import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'MC',
                    label: '名称',
                    minWidth: 105,
                    showoverflowtooltip: true
                },
                {
                    prop: 'JCSJ',
                    label: '监测时间',
                    minWidth: 105,
                    substr: true
                },
                {
                    prop: 'GLZDWSCD',
                    label: '管理制度完善程度',
                    minWidth: 160,
                    transList: {
                        0: "完善",
                        1: "不完善",
                        2: "管理制度变动"
                    }
                },
                {
                    prop: 'LJ',
                    label: '管理制度附件',
                    minWidth: 120,
                    showFileBtn: true
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            },
        },
    },
    snButton: {
        buttonData: [
            {
                isShow: true,
                btnType: 'button',
                operateType: 'buttonNew',
                showMethod: 'showNewBtn',
                name: '新建',
                round: true,
                backColor: '#28ccd9',
            },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [
            // {
            //     "label": "管理制度完善程度",
            //     "clearable": true,
            //     "placeholder": "请选择管理制度完善程度",
            //     "list": [
            //         { "ID": "完善", "NAME": "完善" },
            //         { "ID": "不完善", "NAME": "不完善" },
            //         { "ID": "管理制度变动", "NAME": "管理制度变动" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "GLZDWSCD",
            //     "operateType": "search",
            //     "isShow": true,
            //     "width": "180px"
            // }
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    },
};
export default selfData;